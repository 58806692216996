import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';

@Injectable({
  providedIn: 'root'
})
export class SocketsService {

  constructor(private socket: Socket) {
    this.socket.connect();
    this.socket.on('error', (data) => {
    });
  }

  eventSubscriber(handler) {
    this.socket.fromEvent('GETNOTIFICATIONS').subscribe(handler);
  }

  unAssignedOrdersEventSubscriber(handler) {
    this.socket.fromEvent('GET_UNASSIGNED_ORDERS').subscribe(handler);
  }

  agentCountEventSubscriber(handler){
    this.socket.fromEvent('GET_AGENTS_COUNT').subscribe(handler);
  }

  getLateOrdersEventSubscriber(handler) {
    this.socket.fromEvent('LATE_ORDERS').subscribe(handler);
  }

}