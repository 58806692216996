import { AuthService } from './../../../shared/auth.service';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import MetisMenu from 'metismenujs/dist/metismenujs';

// import { MENU } from './menu';
import { MenuItem } from './menu.model';
import { Router, NavigationEnd } from '@angular/router';
import { SocketsService } from 'src/app/socket-serives/sockets.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit {

  @ViewChild('sidebarToggler') sidebarToggler: ElementRef;
  MENU:any;
  menuItems = [];
  @ViewChild('sidebarMenu') sidebarMenu: ElementRef;
  permissions: string;
  isSuperAdmin: boolean;
  todayUnAssignedOrders : number = 0;
  agentsOfflineCount: number = 0;
  isSubMenuVisible: boolean = false;
  activeLink: any;
  itemExpanded : boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router,
    public apiService: AuthService,
    public socketService : SocketsService
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {

        /**
         * Activating the current active item dropdown
         */
        this._activateMenuDropdown();

        /**
         * closing the sidebar
         */
        if (window.matchMedia('(max-width: 991px)').matches) {
          this.document.body.classList.remove('sidebar-open');
        }

      }
    });
    this.router.events.subscribe(() => {
      this.router.isActive('/dashboard', true);
    });
  }

  ngOnInit(): void {
    this.permissions = sessionStorage.getItem('permission')
    this.isSuperAdmin = sessionStorage.getItem('adminRole') == '_superAdmin_' ? true : false;
    this.initialize();
    /**
     * Sidebar-folded on desktop (min-width:992px and max-width: 1199px)
     */
    const desktopMedium = window.matchMedia('(min-width:992px) and (max-width: 1199px)');
    desktopMedium.addListener(this.iconSidebar);
    this.iconSidebar(desktopMedium);
  }

  initialize(): void {
    this.apiService.unAssignedOrderCount().subscribe((data) => {
      this.apiService.agentCount().subscribe((agentCount) => {
        this.todayUnAssignedOrders = data.count;
        this.agentsOfflineCount = agentCount.count;
        this.generateMenuList();
      })
    });

    this.socketService.unAssignedOrdersEventSubscriber((data) => {
      const ordersMenuItem = this.menuItems.find(item => item.label === 'Requests');
      if (ordersMenuItem) {
        this.todayUnAssignedOrders = data.count;
        this.itemExpanded = true;
        this.generateMenuList();
      }
    });

    this.socketService.agentCountEventSubscriber((data) => {
      this.agentsOfflineCount = data.count;
      this.generateMenuList();
    });
  }
    ngAfterViewInit() {
    // activate menu item
    new MetisMenu(this.sidebarMenu.nativeElement);

    this._activateMenuDropdown();
  }

  /**
   * Toggle sidebar on hamburger button click
   */
  toggleSidebar(e) {
    this.sidebarToggler.nativeElement.classList.toggle('active');
    this.sidebarToggler.nativeElement.classList.toggle('not-active');
    if (window.matchMedia('(min-width: 992px)').matches) {
      e.preventDefault();
      this.document.body.classList.toggle('sidebar-folded');
    } else if (window.matchMedia('(max-width: 991px)').matches) {
      e.preventDefault();
      this.document.body.classList.toggle('sidebar-open');
    }
  }


  /**
   * Toggle settings-sidebar
   */
  toggleSettingsSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('settings-open');
  }


  /**
   * Open sidebar when hover (in folded folded state)
   */
  operSidebarFolded() {
    if (this.document.body.classList.contains('sidebar-folded')){
      this.document.body.classList.add("open-sidebar-folded");
    }
  }


  /**
   * Fold sidebar after mouse leave (in folded state)
   */
  closeSidebarFolded() {
    if (this.document.body.classList.contains('sidebar-folded')){
      this.document.body.classList.remove("open-sidebar-folded");
    }
  }

  /**
   * Sidebar-folded on desktop (min-width:992px and max-width: 1199px)
   */
  iconSidebar(e) {
    if (e.matches) {
      this.document.body.classList.add('sidebar-folded');
    } else {
      this.document.body.classList.remove('sidebar-folded');
    }
  }

  /**
   * Switching sidebar light/dark
   */
  onSidebarThemeChange(event) {
    this.document.body.classList.remove('sidebar-light', 'sidebar-dark');
    this.document.body.classList.add(event.target.value);
    this.document.body.classList.remove('settings-open');
  }


  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }


  /**
   * Reset the menus then hilight current active menu item
   */
  _activateMenuDropdown() {
    this.resetMenuItems();
    this.activateMenuItems();
  }


  /**
   * Resets the menus
   */
  resetMenuItems() {

    const links = document.getElementsByClassName('nav-link-ref');

    for (let i = 0; i < links.length; i++) {
      const menuItemEl = links[i];
      menuItemEl.classList.remove('mm-active');
      const parentEl = menuItemEl.parentElement;

      if (parentEl) {
          parentEl.classList.remove('mm-active');
          const parent2El = parentEl.parentElement;

          if (parent2El) {
            parent2El.classList.remove('mm-show');
          }

          const parent3El = parent2El.parentElement;
          if (parent3El) {
            parent3El.classList.remove('mm-active');

            if (parent3El.classList.contains('side-nav-item')) {
              const firstAnchor = parent3El.querySelector('.side-nav-link-a-ref');

              if (firstAnchor) {
                firstAnchor.classList.remove('mm-active');
              }
            }

            const parent4El = parent3El.parentElement;
            if (parent4El) {
              parent4El.classList.remove('mm-show');

              const parent5El = parent4El.parentElement;
              if (parent5El) {
                parent5El.classList.remove('mm-active');
              }
            }
          }
      }
    }
  };


  /**
   * Toggles the menu items
   */
  activateMenuItems() {

    const links = document.getElementsByClassName('nav-link-ref');

    let menuItemEl = null;

    for (let i = 0; i < links.length; i++) {
      // tslint:disable-next-line: no-string-literal
        if (window.location.pathname === links[i]['pathname']) {

            menuItemEl = links[i];

            break;
        }
    }

    if (menuItemEl) {
        menuItemEl.classList.add('mm-active');
        const parentEl = menuItemEl.parentElement;

        if (parentEl) {
            parentEl.classList.add('mm-active');

            const parent2El = parentEl.parentElement;
            if (parent2El) {
                parent2El.classList.add('mm-show');
            }

            const parent3El = parent2El.parentElement;
            if (parent3El) {
                parent3El.classList.add('mm-active');

                if (parent3El.classList.contains('side-nav-item')) {
                    const firstAnchor = parent3El.querySelector('.side-nav-link-a-ref');

                    if (firstAnchor) {
                        firstAnchor.classList.add('mm-active');
                    }
                }

                const parent4El = parent3El.parentElement;
                if (parent4El) {
                    parent4El.classList.add('mm-show');

                    const parent5El = parent4El.parentElement;
                    if (parent5El) {
                        parent5El.classList.add('mm-active');
                    }
                }
            }
        }
    }
  };
  
  generateMenuList() {
    let datas = JSON.parse(this.permissions);
    const stockPermission = datas?.find((s) => s?.area == 'stock');
    const subItemsForStock = [
      ...(this.isSuperAdmin || (stockPermission?.children?.some((f) => f.area === 'warehouse' && f.access.includes('read')))
        ? [{
          label: 'Create Warehouse',
          link: '/storage',
        }]
        : []),
      ...(this.isSuperAdmin || (stockPermission?.children?.some((f) => f.area === 'purchase' && f.access.includes('read')))
        ? [{
          label: 'Purchase Invoices',
          link: '/storageVoucher'
        }]
        : []),
      ...(this.isSuperAdmin || (stockPermission?.children?.some((f) => f.area === 'stockTransferOut' && f.access.includes('read')))
        ? [{
          label: 'Stock Transfer Out',
          link: '/storageVoucherOut'
        }]
        : []),
      ...(this.isSuperAdmin || (stockPermission?.children?.some((f) => f.area === 'stockTransfer' && f.access.includes('read')))
        ? [{
          label: 'Stock Transfer',
          link: '/transferStorage'
        }]
        : [])
    ];
    this.menuItems = [
      (this.isSuperAdmin || (!this.isSuperAdmin && datas[0].area == 'dashboard' && datas[0].access.includes('read') == true)) ? {
        label: 'Dashboard',
        icon: 'home',
        link: '/dashboard'
      } : false,
      (this.isSuperAdmin || (!this.isSuperAdmin && datas[1].area == 'requests' && datas[1].access.includes('read') == true)) ? {
        label: 'Requests',
        icon: 'codesandbox',
        expanded: this.itemExpanded,
        subItems: [
          {
            label: 'Overall Orders',
            link: '/overall_orders',
          },
          {
            label: 'Assigned Orders',
            link: '/assignment',
          },
          {
            label: `UnAssigned (${this.todayUnAssignedOrders})`,
            link: '/orders',
          },
          {
            label: 'Cancelled Orders',
            link: '/cancelled_orders',
          },
          {
            label: 'Biker Cancel Requests',
            link: '/biker_cancel_requests',
          },
          {
            label: 'Biker Request',
            link: '/washerhelp',
          },
        ]
      } : false,
      (this.isSuperAdmin || (!this.isSuperAdmin && datas[3].area == 'biker' && datas[3].access.includes('read') == true)) ? {
        label: `Biker/ Agent (${this.agentsOfflineCount})`,
        icon: 'truck',
        link: '/agents'
      } : false,
      (this.isSuperAdmin || (!this.isSuperAdmin && datas[2].area == 'customer' && datas[2].access.includes('read') == true)) ? {
        label: 'Customer',
        icon: 'user',
        link: '/clients'
      } : false,
      (this.isSuperAdmin || (!this.isSuperAdmin && datas[4].area == 'settings' && datas[4].access.includes('read') == true)) ? {
        label: 'Master Settings',
        icon: 'list',
        subItems: [
          {
            label: 'Services',
            link: '/service',
          },
          // {
          //   label: 'Subscription',
          //   link: '/subscription',
          // },
          {
            label: 'Products',
            link: '/product'
          },
          {
            label: 'Zones',
            link: '/zones'
          },
          {
            label: 'Time Slot',
            link: '/time'
          },
          {
            label: 'Commissions',
            link: '/commission'
          },
          {
            label: 'Car Brands',
            link: '/carbrand'
          },

          {
            label: 'Car Color',
            link: '/carcolor'
          },
          {
            label: 'Biker Helps',
            link: '/helps'
          },
          {
            label: 'Faq Customer',
            link: '/faq'
          },
          {
            label: 'Nationality',
            link: '/nationality'
          },
          {
            label: 'Noftifcation Content',
            link: '/notificationcontent'
          },
          {
            label: 'Banners',
            link: '/banners'
          },
          {
            label: 'City',
            link: '/city'
          },
          {
            label: 'Country',
            link: '/country'
          },
          {
            label: 'Vendor',
            link: '/vendor'
          },
          // {
          //   label: 'Coupons',
          //   link: '/coupons'
          // },

        ]
      } : false,
      (this.isSuperAdmin || (!this.isSuperAdmin && stockPermission && stockPermission?.access?.includes('read') == true)) ? {
        label: 'Inventory Management',
        icon: 'database',
        subItems: subItemsForStock
      } : false,
      (this.isSuperAdmin || (!this.isSuperAdmin && datas[9].area == 'contentEditor' && datas[9].access.includes('read') == true)) ? {
        label: 'Settings',
        icon: 'settings',
        link: '/settings'
      } : false,
      (this.isSuperAdmin || (!this.isSuperAdmin && datas[5].area == 'subscription' && datas[5].access.includes('read') == true)) ? {
        label: 'Subscription',
        icon: 'shopping-bag',
        link: '/subscription'
      } : false,
      (this.isSuperAdmin || (!this.isSuperAdmin && datas[6].area == 'coupons' && datas[6].access.includes('read') == true)) ? {
        label: 'Coupons',
        icon: 'gift',
        link: '/coupons'
      } : false,
      (this.isSuperAdmin || (!this.isSuperAdmin && datas[7].area == 'notification' && datas[7].access.includes('read') == true)) ? {
        label: 'Notification',
        icon: 'twitch',
        link: '/notify'
      } : false,
      (this.isSuperAdmin || (!this.isSuperAdmin && datas[8].area == 'reports' && datas[8].access.includes('read') == true)) ? {
        label: 'Reports',
        icon: 'file-text',
        subItems: [
          {
            label: 'Biker',
            link: '/bikerreport',
          },
          {
            label: 'Customer',
            link: '/customerreport',
          },
          // {
          //   label: 'Storage Voucher',
          //   link: '/storageVoucherReport',
          // },
          // {
          //   label: 'Voucher Items',
          //   link: '/storageVoucherItemsReport',
          // },
          {
            label: 'Purchase Report',
            link: '/purchase',
          },
          {
            label: 'Transfer Report',
            link: '/transfer',
          },
          {
            label: 'Stock On Hands',
            link: '/stocks',
          },
          {
            label: 'Biker Dates Report',
            link: '/bikerDateReport',
          },
          {
            label: 'Audit Action Report',
            link: '/auditAction',
          },
          //  {
          //   label: 'Orders',
          //   link: '/orders',
          // },
        ]
      } : false,
      this.isSuperAdmin && {
        label: 'Admin Users',
        icon: 'users',
        subItems: [
          {
            label: 'Roles',
            link: '/admin-roles',
          },
          {
            label: 'Users',
            link: '/admin-user',
          },
          {
            label: "Corporate Customer",
            link: '/corporate-customer'
          }
        ]
      },
      (this.isSuperAdmin || (!this.isSuperAdmin && datas[9].area == 'contentEditor' && datas[9].access.includes('read') == true)) ? {
        label: 'Content Editor',
        icon: 'edit-3',
        subItems: [
          {
            label: 'Service Rules',
            link: '/servicerule',
          },
          {
            label: 'Terms & Condition',
            link: '/terms'
          },
          {
            label: 'About Us',
            link: '/aboutus'
          },
          {
            label: 'Privacy Policy',
            link: '/privacypolicy'
          },

        ]
      } : false,
      // {
      //   label: 'Error',
      //   icon: 'cloud-off',
      //   subItems: [
      //     {
      //       label: '404',
      //       link: '/error/404',
      //     },
      //     {
      //       label: '500',
      //       link: '/error/500',
      //     },
      //   ]
      // },
    ];
  }

  navigateToRoute(route: string): void {
    this.router.navigate([route]);
  }

  toggleMenu(item: any, event: Event): void {
    if (this.hasItems(item)) {
      event.preventDefault();
      item.expanded = !item.expanded;
    } else if (item.link) {
      this.navigateToRoute(item.link);
    }
  }

}
