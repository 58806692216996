import { Component, OnInit, ViewChild, ElementRef, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/auth.service';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SocketsService } from 'src/app/socket-serives/sockets.service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  updatedby: any;
  role: any;
  adminName: any;
  adminEmail: any;
  notification:any;
  lang: any;
  dir: any;
  intervalId: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router,
    public fb: FormBuilder,
    private modalService: NgbModal,
    private socketService: SocketsService,
    public authService: AuthService, private toastr: ToastrService, public translate: TranslateService
  ) {
     this.signoutform = this.fb.group({
        lang:"en"
      });
   }

  ngOnInit(): void {
    this.lang = localStorage.getItem("lang") || "en";
    this.translate.use(this.lang);
    this.dir = localStorage.getItem("dir") || "ltr";

    this.updatedby = sessionStorage.getItem('adminId');
    this.role = sessionStorage.getItem('adminRole');
    this.adminName = sessionStorage.getItem('adminName');

    // this.intervalId = setInterval(() => {
    //   this.fetchNotificationData();
    // }, 10000);
    
    this.socketService.eventSubscriber((data) => {

      this.notification.unshift(data.data);
      let audio = new Audio();
      if(data.notificationType == 'productHelpNotificaiton') {
        
        audio.src = "../../../../assets/sounds/biker-request.mp3";
      }
      else if(data.notificationType == 'agentStatusChange'){
        audio.src = "../../../../assets/sounds/biker-off.mp3";
      }
      else if(data.notificationType == 'jobPending'){
        audio.src = "../../../../assets/sounds/late-biker.mp3";
      }
      else if (data.notificationType == "unAssignedOrder") {
        audio.src = "../../../../assets/sounds/un-assigned.mp3";
      }
      else {
        audio.src = "../../../../assets/sounds/new-order.mp3";
      }
      audio.load();
      audio.play();
      
      console.log(`notification received: ${JSON.stringify(data)}`);
    })
    
    this.fetchNotificationData();
  }

  fetchNotificationData() {
    this.authService.getadminotifiaction({})
      .subscribe((res: any) => {
        if (res.error == false) {
          // Success
        this.notification = res.data;
        // console.log("notify",this.notification)
        } else {
          // Query Error
          this.toastr.error(res.message);
        }
      });
  }

   signoutform = this.fb.group({
        lang:"en"
      });

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }
  

  openModal(password: any) {
    // this.validationform.reset();
    // this.isEdit = false;
    this.modalService.open(password, { centered: true });
  }
  resetpop(){}
  /**
   * Logout
   */
  onLogout() {
     this.authService.doLogout(this.signoutform.value)
      .subscribe((res: any) => {
        if (res.error == false) {
          // Success
          this.toastr.success("LogOut Successfully",res.message);
          this.router.navigate(['/auth/login']);
         sessionStorage.clear();
        } else {
          // Query Error
          this.toastr.error(res.message);
        }
      });
}
switchLang(lang: any) {
  // console.log("lng",lang)
  if (lang == "en") {
    var dir = "ltr";
  } else {
    var dir = "rtl";
  }
  localStorage.setItem("lang", lang);
  localStorage.setItem("dir", dir);
  // console.log("lang",localStorage)
  window.location.reload();

}

}
