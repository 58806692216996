export const environment = {
  production: true,
  /* local server ip */
  //  baseUrl: 'http://localhost:8080/api/v1/admin',
  //  newbaseUrl: 'http://localhost:8080/api/v2/admin',
  //s3Url: 'http://52.66.187.179:8080/api/v1'

  /* Test Server IP */
  // newbaseUrl: 'http://52.66.187.179:8080/api/v2/admin',
  // baseUrl: 'http://52.66.187.179:8080/api/v1/admin',
  // s3Url: 'http://52.66.187.179:8080/api/v1',

  /* Live Server IP */
  newbaseUrl: 'https://ghasslapp.com/api/v2/admin',
  baseUrl: 'https://ghasslapp.com/api/v1/admin',
  s3Url: 'https://ghasslapp.com/api/v1'
};
